import React from 'react'
import { graphql } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import * as styles from './deals.module.scss'
import { tr } from 'js/translations.js'

import Banner from 'components/Banner'
import Breadcrumb from 'components/Breadcrumb'
import DealCategory from 'components/DealCategory'
import ImageWrap from 'components/ImageWrap'
import IntroText from 'components/IntroText'
import Inview from 'components/Inview'
import Layout from 'components/Layout'
import Seo from 'components/Seo'

const Page = ({ data }) => {
  const locale = process.env.GATSBY_LOCALE
  const page = data['page'].nodes[0]

  const breadcrumb = [
    {
      text: tr('HOME', locale),
      link: '/',
    },
    {
      text: page.title,
    },
  ]
  const introText = renderRichText(page.introText)

  return (
    <Layout>
      <div>
        <Seo page={page} locale={locale} />
        <Banner image={page.banner} locale={locale} />
        <Breadcrumb items={breadcrumb} />

        <Inview>
          <div className={`${styles.mapWrapper} wrapper pad-sides`}>
            <div className='inner'>
              <IntroText
                content={introText}
                hideBorder={true}
                addClass='map-block'
              />
              <div className={styles.mapImage}>
                <div className={styles.desktop}>
                  <ImageWrap
                    className='fade-in slower stagger-500'
                    image={page.map}
                  />
                </div>
                <div className={styles.mobile}>
                  <ImageWrap
                    className='fade-in slower stagger-500'
                    image={page.mapMobile}
                  />
                </div>
              </div>
            </div>
          </div>
        </Inview>

        <div className='wrapper pad-sides'>
          <div className='inner'>
            <div className={styles.deals}>
              {page.dealCategories.map((category, i) => (
                <DealCategory key={i} category={category} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    page: allContentfulDealsPage {
      nodes {
        title
        banner {
          ...GatsbyImage
        }
        introText {
          raw
        }
        map {
          ...GatsbyImage
        }
        mapMobile {
          ...GatsbyImage
        }
        dealCategories {
          title
          deals {
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1.2
                    transformOptions: { cropFocus: CENTER }
                  )
                }
              }
            }
            title
            excerpt
            tags
          }
        }
        seo {
          ...SeoFields
        }
      }
    }
  }
`
